import React from 'react';
import { AppRouter } from './AppRouter';

export default function App()
{
    return (
        <div className="App" >
            {/* <AppNavBar /> */}
            <AppRouter />
            {/* <Footer /> */}
        </div>
    );
}
